import React from 'react';
import styled from 'styled-components';
import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import TaxiIcon from "../assets/icons/car.svg";
import CarParkTable from '../components/CarParkTable';
import { useTranslation } from 'react-i18next';

import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';

const Content = styled.div`
  // height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 100;
`;

const Container = styled.div`
  width: 100%;
  
`;

const Flex = styled.div`
  display: flex;
`;



function CarPark() {
  const { showPopupUser } = useHotspotUserInfo();

  const { t } = useTranslation();

  return (
    <HomeContainer>
      <Flex>
        <Container>
          <Title icon={TaxiIcon} text="carPark" />
          <Content>
            <Container>
              <CarParkTable />
        
            </Container>
          </Content>
        </Container>
      </Flex>
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default CarPark;

